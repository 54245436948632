<template>
  <v-row>
    <v-col
        v-for="(current_zona, index) in zonas" :key="index"
        cols="12"
        sm="6"
        md="4"
        class="pt-0 pb-0"
    >
      <v-checkbox
          v-model="selected"
          :label="current_zona.descripcion"
          :value="current_zona.id"
          hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Zonas',
  data(){
    return {
      zonas: [],
      servicios_zonas: {},
      servicios_zonas_personalizados: [
        {
          servicio_id: 29747, // GAMBE COMPLETE, PIEDI, INGUINE, PUBE, PERIANALE, ASCELLE E VISO O LINEA ALBA
          zonas: [51, 41, 54, 32] // PIERNAS ENTERAS Y PIES - INGLES, PUBIS, PERIANAL - AXILAS - CARA
        },
        {
          servicio_id: 29747, // PIERNAS ENTERAS, PIES, INGLES, PUBIS, PERIANAL, AXILAS Y CARA O LINEA ALBA
          zonas: [51, 41, 54, 43] // PIERNAS ENTERAS Y PIES - INGLES, PUBIS, PERIANAL - AXILAS - LINEA ALBA
        },
        {
          servicio_id: 29859, // MEDIAS PIERNAS, PIES, INGLES, PUBIS, PERIANAL, AXILAS Y CARA O LINEA ALBA
          zonas: [46, 41, 54, 32] // MEDIAS PIERNAS+PIES - INGLES, PUBIS, PERIANAL - AXILAS - CARA
        },
        {
          servicio_id: 29859, // MEDIAS PIERNAS, PIES, INGLES, PUBIS, PERIANAL, AXILAS Y CARA O LINEA ALBA
          zonas: [46, 41, 54, 43] // MEDIAS PIERNAS+PIES - INGLES, PUBIS, PERIANAL - AXILAS - LINEA ALBA
        },
        {
          servicio_id: 29884, // INGLES, PUBIS, PERIANAL, AXILAS, CARA O LINEA ALBA
          zonas: [41, 54, 32] // INGLES, PUBIS, PERIANAL - AXILAS - CARA
        },
        {
          servicio_id: 29884, // INGLES, PUBIS, PERIANAL, AXILAS, CARA O LINEA ALBA
          zonas: [41, 54, 43] // INGLES, PUBIS, PERIANAL - AXILAS - LINEA ALBA
        },

      ],
      selected: []
    }
  },

  methods: {
    async get_zonas(){
      this.zonas = await this.$store.dispatch('centro/get_zonas', {
        empresa: 109
      })
    },

    /**
     * Crea un map en el que la key es el id del servicio y el value un array de zonas
     */
    parse_servicios_zonas(){
      this.zonas.forEach(current_zona => {
        current_zona.servicios.forEach(current_servicio => {
          if(!this.servicios_zonas[current_servicio['id']]){
            this.servicios_zonas[current_servicio['id']] = []
          }
          this.servicios_zonas[current_servicio['id']].push(current_zona.id)
        })
      })
    }
  },

  async mounted() {
    await this.get_zonas()
    this.parse_servicios_zonas()

  },

  watch: {
    'selected': function (new_value) {
      if(new_value.length === 0) {
        this.$emit('on_servicios_encontrados', null)
        return
      }

      const ZONA_GENITALES_MASCULINOS_ID = 37
      const SERVICIO_GENITALES_MASCULINOS_ID = 29756

      let zonas_seleccionadas = this.zonas.filter(function(z){
        return new_value.includes(z.id)
      }).map((z) => z.id)
      let is_genitales_masculinos = zonas_seleccionadas.includes(ZONA_GENITALES_MASCULINOS_ID)

      // Si hay varias zonas seleccionadas y se incluye los genitales masculinos, lo sacamos del array para añadirlo manualmente
      const sacar_zona_genitales_masculinos = zonas_seleccionadas.length > 1 && is_genitales_masculinos
      if(sacar_zona_genitales_masculinos){
        const index = zonas_seleccionadas.indexOf(ZONA_GENITALES_MASCULINOS_ID);
        if (index !== -1) {
          zonas_seleccionadas.splice(index, 1);
        }
      }

      let respuesta = []
      // Buscamos si algún servicio incluye las zonas seleccionadas
      for (let servicio_id of Object.keys(this.servicios_zonas)) {
        if(zonas_seleccionadas.sort().join(',') === this.servicios_zonas[servicio_id].sort().join(',')){
          respuesta.push(parseInt(servicio_id))
        }else{
          // Buscamos si está en servicios personalizados
          this.servicios_zonas_personalizados.forEach((current) => {
            if(zonas_seleccionadas.sort().join(',') === current['zonas'].sort().join(',')){
              respuesta.push(parseInt(current['servicio_id']))
            }
          })
        }
      }

      // Si hemos sacado la zona de genitales masculinos y las zonas seleccionadas ya incluyen algún servicio, lo incluimos en la respuesta
      if(sacar_zona_genitales_masculinos && respuesta.length > 0){
        respuesta.push(SERVICIO_GENITALES_MASCULINOS_ID)
      }
      this.$emit('on_servicios_encontrados', respuesta)
    }
  }

}
</script>
